




















import { Component, Prop, Vue } from 'vue-property-decorator'
import defaultAvatar from '@/assets/avatar.png'
import { toLiveRoom } from '@/common/utils'
@Component({
  name: 'Avatar'
})
export default class Avatar extends Vue {
  @Prop()
  url!: string
  @Prop({ default: 0 })
  uid!: string | number
  @Prop({ default: defaultAvatar })
  defaultAvatar!: string

  tocenter() {
    toLiveRoom(this.uid)
  }
}
