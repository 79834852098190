































import { Component, Mixins } from 'vue-property-decorator'
import ToDownLoad from '@/pages/videoGame/components/ToDownLoad/ToDownLoad.vue'
import Avatar from '@/components/Avatar.vue'
import { OutsidePageMixin } from '@/mixins/outsidePage'
import { getQueryVariable } from '@/common/utils/index'
import { getShareInviteInfo } from '@/pages/videoGame/api'
interface UserInfoModel {
  avatarThumb: string
  nickname: string
  userNumber: string
}
interface RoomDataInfoModel {
  roomThemeTitle: string
  thumb: string
  title: string
}

@Component({
  name: 'ShareRoom',
  components: {
    ToDownLoad,
    Avatar
  }
})
export default class ShareRoom extends Mixins(OutsidePageMixin) {
  userInfo: any = {}
  roomDataInfo: RoomDataInfoModel = {} as RoomDataInfoModel
  downLoadUrl = ''
  shareuid = ''
  async created() {
    this.shareuid = getQueryVariable('shareuid') || ''
    this.getData()
  }
  async copyTextToClipboard() {
    try {
      const text = this.userInfo.inviteCode
      await navigator.clipboard.writeText(text)
      this.$toast(this.$t('复制成功'))
    } catch (err) {
      console.error('Failed to copy text: ', err)
      this.$toast(this.$t('复制失败'))
    }
  }
  goApp() {
    //
    this.handleOpenEnjoyApp(this.downLoadUrl)
  }
  async getData() {
    const params = {
      uid: this.shareuid
    }
    const { data } = await getShareInviteInfo(params)
    this.userInfo = data
  }
}
